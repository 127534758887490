
import Vue from "vue";
import { mapState } from "vuex";
import { parseComponent } from "vue-template-compiler";
import { Project, View } from "@/code/project";

export default Vue.extend({
  components: {
  },
  name: "Run",

  data: () => ({}),

  computed: {
    ...mapState(["session"]),

    template() {
      let project = this.session.projects.find(
        (p: Project) => p.id == this.$route.params.projectId
      );

      let t = {}; // '<template>blah</template>';
      project.views.forEach((page: View) => {
        t = this.buildCodeToComponent(page.serialize());
      });

      console.log(t);

      return t;
    },
  },

  methods: {
    buildCodeToComponent(code: string) {
      return Vue.compile(code);
    //   const SFC = parseComponent(code);
    //   let component = { template: "" };

    //   if (SFC.script) {
    //     component = eval(
    //       SFC.script.content.replace("export default", "component =")
    //     );
    //   }

    //   if (SFC.template) {
    //     component.template = SFC.template.content;
    //   } else {
    //     component.template = `<div style="width: 100%; height: 100%">${code}</div>`;
    //   }

    //   return component;
    // },
  },
}
});
